import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ChevronLeft, ChevronRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

export interface Props {
  direction?: 'L' | 'R'
  onClick?: (e: any) => void
  variant?: Variant
}

export const Arrow = memo(function Arrow({
  direction = 'L',
  onClick,
  variant = 'default',
}: Props) {
  return (
    <Container dial={5} onClick={onClick} variant={variant}>
      {direction === 'L' ? <ChevronLeft /> : <ChevronRight />}
    </Container>
  )
})

const Container = styled(FlexBox)<ContainerProps>`
  width: 5rem;
  height: 5rem;
  border: 0.125rem solid ${rgba(theme.colors.variants.secondaryLight, 0.2)};
  border-radius: 50%;
  cursor: pointer;
  margin-left: 0.75rem;
  transition: 0.3s ease-in-out;
  &:hover {
    border-color: ${({ theme }) => theme.colors.variants.secondaryLight};
  }
  &:first-of-type {
    margin-left: 0;
  }

  svg {
    width: auto;
    height: 1.5rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.secondaryLight};
    stroke-width: 2;
    transition: 0.2s ease-in-out;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'small':
        return css`
          width: 2.5rem;
          height: 2.5rem;
          border: 0;
          border-radius: 0;
          margin-left: 0;
          &:hover {
            svg {
              stroke: ${theme.colors.variants.secondaryDark};
            }
          }
          svg {
            stroke: ${theme.colors.variants.secondaryLight};
          }
        `
      case 'invert':
        return css`
          border-color: ${rgba(theme.colors.variants.neutralLight4, 0.2)};
          &:hover {
            border-color: ${theme.colors.variants.neutralLight4};
          }
          svg {
            stroke: ${theme.colors.variants.neutralLight4};
          }
        `
    }
  }}
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'small' | 'invert'
